import { css } from "@emotion/css";
import React from "react";
import ReactDOM from "react-dom";
import { BasisPlaidApp } from "./BasisPlaidApp";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";

const appContainerStyles = css`
  background-color: rgba(144, 144, 144, 0.98);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

ReactDOM.render(
  <React.StrictMode>
    <div className={appContainerStyles}>
      <BasisPlaidApp />
    </div>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals(sendToVercelAnalytics);
