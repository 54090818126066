/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback } from "react";

import { usePlaidLink } from "react-plaid-link";
import { plaidLinkItemUrl, plaidLinkLogUrl } from "../utils/api";

export const PlaidWrapper = ({
  companyId,
  plaidToken: token,
  setError,
  setShowDashboard,
  setLoading,
}) => {
  // This needs to have company id
  const onSuccess = useCallback(async (publicToken, metadata) => {
    // log and save metadata
    // exchange public token
    try {
      const body = {
        publicToken,
        linkSessionId: metadata.link_session_id,
      };
      await fetch(plaidLinkItemUrl(companyId), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      setShowDashboard(true);
      setLoading(true);
    } catch (err) {
      console.log(err, "add item error");
      setError(true);
    }
  });

  const onExit = useCallback((error, metadata) => {
    // log and save error and metadata
    // handle invalid link token
    if (error != null) {
      setError(true);
      // generate new link token
    }
    setShowDashboard(true);
    setLoading(true);
    // to handle other error codes, see https://plaid.com/docs/errors/
  }, []);

  const log = async (metadata) => {
    await fetch(plaidLinkLogUrl(companyId), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(metadata),
    });
  };

  const { open, ready } = usePlaidLink({
    onSuccess,
    onExit,
    onEvent: (eventName, metadata) => {
      log(metadata);
    },
    token: token,
    //required for OAuth; if not using OAuth, set to null or omit:
    // receivedRedirectUri: window.location.href,
  });

  if (ready) {
    open();
  }

  return <div></div>;
};
