import { css, keyframes } from "@emotion/css";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { PlaidWrapper } from "../PlaidWrapper";
import { plaidLinkItemsUrl, plaidLinkTokenUrl } from "../utils/api";

const connectionWrapperStyles = css`
  text-align: left;
  font-size: 24px;
  flex-direction: column;
  height: 100%;
  display: flex;
`;

const loadingKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loadingStyles = css`
  display: inline-block;
  width: 80px;
  height: 80px;
  :after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ${loadingKeyframes} 1.2s linear infinite;
  }
`;

// const itemstyles = css`
//   font-size: 20px;
//   margin: 4px 0;
// `;

const messageStyles = css`
  margin: 20px 0;
  font-family: Cern, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 2rem;
  text-align: center;
  color: #111111;
`;

const smallMessageStyles = css`
  font-family: Cern, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  color: #222222;
  margin-left: 0.05rem;
  font-weight: 500;
  line-height: 20px;
`;

const medMessageStyles = css`
  font-family: Cern, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  color: #070707;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
`;

const addConnectonButtonStyles = css`
  margin-top: 200px;
  margin-bottom: 10px;
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0);
  color: white;
  text-align: center;
  font-weight: 600;
  width: 100%;
  height: 3rem;
  font-size: 2rem;
  font-size: 20px;
  font-family: Cern, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 0px 25px;
  background-color: #111111;
  transition: all 0.2s ease 0s;

  /* Add hover state */
  &:hover {
    background-color: #0072cf;
    cursor: pointer;
  }
`;

export const BasisPlaidApp = () => {
  const [plaidToken, setPlaidToken] = useState();
  const [showDashboard, setShowDashboard] = useState(true);
  const [items, setItems] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [stateCompanyId, setStateCompanyId] = useState();

  const reconnectPlaid = (update_token) => {
    setPlaidToken(update_token);
    setShowDashboard(false);
  };

  useEffect(() => {
    const fetchAndSetToken = async () => {
      setLoading(true);
      const parsed = queryString.parse(window.location.search);
      const { companyId, authId } = parsed;
      if (companyId && authId) {
        setStateCompanyId(companyId);
        try {
          const response = await fetch(plaidLinkTokenUrl(companyId), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          });
          const data = await response.json();
          setPlaidToken(data.linkToken);
        } catch (err) {
          console.log(err, "link token error");
          setError(true);
        }
        try {
          const response = await fetch(plaidLinkItemsUrl(companyId));
          const data = await response.json();

          setItems(data.results);
        } catch (err) {
          console.log(err, "connections error");
          setError(true);
        }
        setLoading(false);
      }
    };
    if (showDashboard) {
      fetchAndSetToken();
    }
  }, [showDashboard]);

  if (loading) {
    return <div className={loadingStyles}></div>;
  }

  if (error && !loading) {
    return <div>Something went wrong! Please Try again</div>;
  }

  // we are in the process of adding a new item
  if (!showDashboard) {
    return (
      <PlaidWrapper
        companyId={stateCompanyId}
        plaidToken={plaidToken}
        setError={setError}
        setShowDashboard={setShowDashboard}
        setLoading={setLoading}
      />
    );
  }

  // after successfully adding an item, show a list of connections
  // show plaid modal when user wants to add another connection

  if (showDashboard) {
    return (
      <>
        {/* <ul>
          {items && items.map((item, key) => (
            <li key={key} className={messageStyles}>
              {item.name}
            </li>
          ))}
        </ul> */}
        <div
          style={{
            background: "#fff",
            borderRadius: "10px",
            height: "608px",
            width: "min(80%, 480px)",
            padding: "20px",
            flexDirection: "column",
            boxSizing: "border-box",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className={connectionWrapperStyles}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      color: "222222",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      margin: "10px",
                    }}
                  >
                    <p className={medMessageStyles}>Connection</p>
                  </div>
                </div>
                {items?.map((connection) => (
                  <>
                    <div
                      key={connection.item_id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          margin: "10px",
                        }}
                      >
                        <p className={smallMessageStyles}>
                          {connection.institution_name}
                        </p>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          margin: "10px",
                          cursor: connection.plaid_error
                            ? "pointer"
                            : "default",
                        }}
                        onClick={() =>
                          connection.plaid_error
                            ? reconnectPlaid(connection.update_token)
                            : null
                        }
                      >
                        <p
                          className={smallMessageStyles}
                          style={{
                            color: connection.plaid_error
                              ? "#303030"
                              : "rgba(48, 48, 48, 0.5)",
                            textDecoration: connection.plaid_error
                              ? "underline"
                              : "",
                          }}
                        >
                          {connection.plaid_error ? "Reconnect" : "Connected"}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#E5E5E5",
                      }}
                    />
                  </>
                ))}
              </div>
              <button
                className={addConnectonButtonStyles}
                onClick={() => setShowDashboard(false)}
              >
                Add a New Connection
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
};
