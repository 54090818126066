// const BASE_PATH = process.env.WEBSERVER

const BASE_PATH = process.env.REACT_APP_API_URL;

export const plaidLinkTokenUrl = (companyId) =>
  `${BASE_PATH}/v1/companies/${companyId}/link/token/create`;

// TODO change to post and correct pass in the data
export const plaidLinkItemUrl = (companyId) =>
  `${BASE_PATH}/v1/companies/${companyId}/link/item`;

export const plaidLinkLogUrl = (companyId) =>
  `${BASE_PATH}/v1/companies/${companyId}/plaid/log`;

export const plaidLinkItemsUrl = (companyId) =>
  `${BASE_PATH}/v1/companies/${companyId}/connections`;
